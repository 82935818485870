import { createContext, useContext, useEffect, useReducer } from "react";
import { endpoint } from "../Constants";
import { onAuthStateChanged } from "firebase/auth";
import { addUser, removeUser } from "../redux/slices/userSlice";
import { analytics, auth, logGAEvent } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { showToast, validateEmail } from "../utils";

import {
  attachFinishedListener,
  checkMSLCodeExists,
  getAssets,
  getUserImageData,
  loginSignUp,
  logout,
  readData,
  removeFinishedListener,
  updateUserData,
} from "../firebase/auth";
import { EVENTS } from "../Constants/GAEvents";
import { doctorNameLimit } from "../components/FormScreen";
// import { appData } from "../Constants/data/appData";
// import { assetsData } from "../Constants/data/assets";

const homeContext = createContext();
export const prefix = "Dr. ";
export const ScreenType = {
  START_SCREEN: "START_SCREEN",
  FORM_SCREEN: "FORM_SCREEN",
  GENDER_SCREEN: "GENDER_SCREEN",
  TEMPLATE_SCREEN: "TEMPLATE_SCREEN",
  CAMERA_SCREEN: "CAMERA_SCREEN",
  FORM_SCREEN: "FORM_SCREEN",
  DASHBOARD_SCREEN: "DASHBOARD_SCREEN",
};

const defaultErr = {
  gender: "",
  employeeID: "",
  name: "",
  email: "",
  tags: "",
  language: "",
  speciality: "",
  mobile: "",
  hq: "",
  doctorsName: "",
  MSLCode: "",
};

const formDetails = {
  gender: localStorage.getItem("gender") || "male",
  employeeID: "",
  name: "",
  email: "",
  tags: [],
  language: "",
  speciality: "",
  templateId: "",
  jobId: "",
  mobile: "",
  hq: "",
  doctorsName: prefix,
  MSLCode: "",
};

const initialState = {
  screen: ScreenType.START_SCREEN,
  isLoading: false,
  isCameraPermission: false,
  error: null,
  base64Img: "",
  docImage: "",
  assetsData: null,
  videoData: null,
  appData: {
    languages: {},
    speciality: {},
    currentEvent: "WorldSightDay2024",
  },
  formDetails: { ...formDetails },
  formErrors: {
    ...defaultErr,
  },
};

const Action_Type = {
  updateScreen: "updateScreen",
  handleError: "handleError",
  handleInputChange: "handleInputChange",
  loading: "loading",
  resetState: "resetState",
};

export const useHomeContext = () => {
  return useContext(homeContext);
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.updateScreen:
    case Action_Type.handleError:
    case Action_Type.loading:
      return { ...state, ...payload };
    case Action_Type.handleInputChange:
      return {
        ...state,
        formDetails: { ...state.formDetails, [payload.name]: payload.value },
      };
    case Action_Type.resetState:
      return {
        ...initialState,
        appData: state.appData,
        assetsData: state.assetsData,
      };

    default:
      return { ...state };
  }
};

export const HomeContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const user = useSelector((state) => state.user);
  window.currentEvent = state.appData.currentEvent;

  useEffect(() => {
    if (user) {
      if (!state.formDetails.email) {
        updateMultipleState({
          formDetails: {
            ...state.formDetails,
            email: user.email,
            doctorsName: user.displayName,
          },
        });
      }
      // const _getUserImageData = async () => {
      //   let _rs = await getUserImageData(user);

      //   if (_rs) {
      //     updateMultipleState({
      //       base64Img: _rs["url"],
      //       // formDetails: {
      //       //   ...state.formDetails,
      //       //   gender: _rs["gender"] ?? "",
      //       // },
      //     });
      //   }
      // };

      // _getUserImageData();

      attachFinishedListener(user, (cb) => {
        if (cb) {
          dispatch({
            type: Action_Type.loading,
            payload: { videoData: cb },
          });
        }
      });
    }

    return () => {
      removeFinishedListener();
    };
  }, [user]);

  useEffect(() => {
    getAllAssets();
    // getAppData();
  }, []);

  const getAllAssets = async () => {
    let data = await getAssets();
    updateMultipleState({ assetsData: data });
  };
  const getAppData = async () => {
    let node = `/appData`;
    let data = await readData(node);
    if (data) {
      updateMultipleState({ appData: { ...data } });
    }
  };

  useEffect(() => {
    const requestPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        updateMultipleState({ isCameraPermission: true });
        // Remember to stop the stream to free up resources
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        updateMultipleState({ isCameraPermission: false });
        console.error(error);
      }
    };
    if (window.location.pathname === "/") {
      requestPermissions();
    }
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // check if it is returning user and before this timestamp then logout
        let u = localStorage.getItem("user");
        if (u) {
          let parsedUser = JSON.parse(u);
          if (parsedUser.lastLoginAt) {
            // Parse the last login date into a timestamp (if it's a Date object or string)
            let lastLoginTimestamp = new Date(parseInt(parsedUser.lastLoginAt)).getTime();

            let particularDateTime = new Date('2025-01-30T00:00:00Z').getTime();

            // Compare the timestamps
            if (lastLoginTimestamp < particularDateTime) {
              logout()
            }
          }
        }
        reduxDispatch(addUser(JSON.stringify(user)));
      } else {
        window.dataFetched = false;
        resetState();
        localStorage.clear();
        reduxDispatch(removeUser());
      }
    });
  }, []);

  useEffect(() => {
    logGAEvent(EVENTS.PAGE_VISITED, {
      screen: state.screen,
      date: new Date().getTime(),
    });
  }, [state.screen]);

  const updateScreen = (screen) => {
    dispatch({ type: Action_Type.updateScreen, payload: { screen: screen } });
  };
  const updateMultipleState = (data) => {
    dispatch({ type: Action_Type.updateScreen, payload: { ...data } });
  };

  const manageBase64inState = (img = "") => {
    dispatch({
      type: Action_Type.updateScreen,
      payload: { base64Img: img, docImage: "" },
    });
  };

  const handleInputChange = (event) => {
    if (event && event.target) {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      if (name === "doctorsName") {
        if (value?.length > doctorNameLimit) {
          return;
        }
        if (!value.startsWith(prefix)) {
          dispatch({
            type: Action_Type.handleInputChange,
            payload: { name, value: prefix },
          });
        } else {
          dispatch({
            type: Action_Type.handleInputChange,
            payload: { name, value },
          });
        }
      } else {
        dispatch({
          type: Action_Type.handleInputChange,
          payload: { name, value },
        });
      }
    } else {
      dispatch({
        type: Action_Type.handleInputChange,
        payload: { name: event.name, value: event.value },
      });
      if (event.name == "gender") {
        updateUserData(user.uid, {
          gender: event.value,
          events: [state.appData.currentEvent],
        });
      }
    }
    // if(event)
  };
  // console.log("state.appData", state.appData);
  const saveDoctorDataInDB = async () => {
    try {
      const nameRegex = /^[A-Za-z\s.]+$/; // Allows only letters, spaces, and dots

      let _formDetails = { ...state.formDetails };
      let doctorName = _formDetails.doctorsName?.trim();

      // Ensure input starts with "Dr. " and check characters after it
      if (!doctorName || doctorName.length <= prefix.length) {
        showToast("Please enter a valid doctor name after 'Dr.'.", {
          type: "error",
        });
        return;
      }

      const nameWithoutPrefix = doctorName.startsWith(prefix)
        ? doctorName.slice(prefix.length).trim() // Remove "Dr. " for validation
        : doctorName.trim(); // Fallback, in case prefix is missing

      if (!nameWithoutPrefix) {
        showToast("Please enter your name after 'Dr.'.", { type: "error" });
        return;
      }

      if (!nameRegex.test(nameWithoutPrefix)) {
        showToast(
          "Name should only contain alphabets. Please remove any numbers, special characters, or extra spaces.",
          { type: "error" }
        );
        return;
      }

      if (!_formDetails.email) {
        showToast("Please Enter Your Email.", { type: "error" });
        return;
      }
      if (!validateEmail(_formDetails.email)) {
        showToast("Email must be in the format: example@xyz.com", {
          type: "error",
        });
        return;
      }

      let email = `${_formDetails.email}`.toLowerCase();
      let password = `${_formDetails.email}1234`.toLowerCase();

      let _u = user;
      if (!_u) {
        updateMultipleState({ isLoading: true });
        _u = await loginSignUp(_formDetails.doctorsName, email, password);
      }
      if (_u && _u.email !== _formDetails.email) {
        logout();
        updateMultipleState({ isLoading: true });
        _u = await loginSignUp(_formDetails.doctorsName, email, password);
      }

      logGAEvent(
        EVENTS.LOGIN_BTN,
        {
          name: _formDetails.doctorsName,
          uid: _u.uid,
        },
        {}
      );

      updateUserData(_u.uid, {
        name: _formDetails.doctorsName,
        email: _formDetails.email,
        gender: "male",
        events: [state.appData.currentEvent],
      });
      let obj = {
        gender: "male",
        isLoading: false,
        screen: ScreenType.TEMPLATE_SCREEN,
      };
      updateMultipleState(obj);
      localStorage.setItem("gender", "male");
    } catch (error) {
      updateMultipleState({ isLoading: false });
      showToast("Something went wrong!", { type: "error" });
    }
  };

  const generateVideo = async ({
    templateId = "",
    cleanTemplateId = false,
  }) => {
    let _templateid = state.formDetails.templateId || templateId;
    if (!_templateid || !user || state.isLoading) {
      console.warn("field missing");
      return;
    }

    updateMultipleState({ isLoading: true });
    let _obj = {
      assetId: _templateid,
      userId: `${user.uid}`,
      docName: state.formDetails.doctorsName.trim(),
      email: state.formDetails.email.trim(),
      gender: state.formDetails.gender,
      eventId: state.appData.currentEvent,
    };
    if (state.formDetails.jobId) {
      _obj["jobId"] = state.formDetails.jobId;
    }
    if (state.docImage && _obj["jobId"]) {
      _obj["docImage"] = state.docImage;
    } else {
      _obj["imageAsBase64"] = state.base64Img;
    }

    // let obj = {
    //   isLoading: true,
    //   screen: ScreenType.DASHBOARD_SCREEN,
    //   docImage: "",
    //   formDetails: {
    //     ...state.formDetails,
    //     jobId: "",
    //     templateId: cleanTemplateId ? "" : _templateid,
    //   },
    // };
    // showToast("Video added to the queue", { type: "success" });
    // updateMultipleState(obj);

    fetch(`${endpoint}/videoAIPipelineV2`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(_obj),
    })
      .then((res) => {
        if (res.status === 200) {
          let obj = {
            isLoading: false,
            screen: ScreenType.TEMPLATE_SCREEN,
            docImage: "",
            formDetails: {
              ...state.formDetails,
              jobId: "",
              templateId: cleanTemplateId ? "" : _templateid,
            },
          };
          showToast(
            "Hang tight! Your video is being generated. Estimated time: 3 minutes.",
            { type: "success" }
          );
          updateMultipleState(obj);
        } else {
          updateMultipleState({ isLoading: false });
          if (res.status === 429) {
            showToast("Limit Exhausted !", { type: "error" });
          } else {
            showToast("Something went wrong!", { type: "error" });
          }
        }
      })
      .catch((error) => {
        showToast("Something went wrong!", { type: "error" });
        updateMultipleState({ isLoading: false });
        console.error(`error`, error);
      });
  };

  const validateForm = () => {
    let errors = state.formErrors;

    const nameRegex = /^[A-Za-z\s.]+$/; // Allows letters, spaces, and dots

    const doctorName = state.formDetails.doctorsName?.trim();

    // Ensure input starts with "Dr. " and check characters after it
    if (!doctorName || doctorName.length <= prefix.length) {
      errors.doctorsName = "Please enter a valid doctor name.";
    } else {
      const nameWithoutPrefix = doctorName.slice(prefix.length).trim(); // Remove "Dr. " for validation

      errors.doctorsName = nameWithoutPrefix
        ? nameRegex.test(nameWithoutPrefix)
          ? ""
          : "Doctor's name should only contain letters."
        : "Please enter a valid doctor name.";
    }

    dispatch({
      type: Action_Type.handleError,
      payload: { formErrors: { ...errors } },
    });
  };
  const validateMSLCode = async () => {
    let errors = state.formErrors;
    let _mslCode = state.formDetails.MSLCode.trim();
    // Check if MSLCode exists and does not contain any spaces
    if (!_mslCode) {
      errors.MSLCode = "Please enter valid MSL code";
    } else if (/\s/.test(_mslCode)) {
      errors.MSLCode = "MSL code should not contain any spaces";
    } else {
      errors.MSLCode = "";
    }

    // check if this code exits in employee database
    let res = await checkMSLCodeExists(
      `${state.formDetails.employeeID}`,
      _mslCode
    );
    if (res) {
      errors.MSLCode = res;
    }

    dispatch({
      type: Action_Type.handleError,
      payload: { formErrors: { ...errors } },
    });
  };

  const resetFormErrors = () => {
    dispatch({
      type: Action_Type.handleError,
      payload: { formErrors: { ...defaultErr } },
    });
  };

  const resetState = () => {
    dispatch({
      type: Action_Type.resetState,
    });
  };

  const value = {
    state,
    handleInputChange,
    updateScreen,
    manageBase64inState,
    updateMultipleState,
    generateVideo,
    resetFormErrors,
    validateForm,
    saveDoctorDataInDB,
    validateMSLCode,
  };
  return (
    <homeContext.Provider value={value}>{props.children}</homeContext.Provider>
  );
};
