import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ANIMATION_TYPE } from "../Constants";

const emptyObj = {};
const useGSAPAnimation = (props) => {
  const { target, animationType = "" } = props || emptyObj;

  const { contextSafe } = useGSAP(() => {
    switch (animationType) {
      case ANIMATION_TYPE.FADE_IN_UP:
        gsap.fromTo(
          target,
          { y: "+=100", opacity: 0 },
          {
            stagger: 0.1,
            opacity: 1,
            y: 0,
            duration: 1,
          }
        );
        break;
      case ANIMATION_TYPE.FADE_IN:
        gsap.fromTo(
          target,
          { opacity: 0.2 },
          {
            stagger: 0.1,
            opacity: 1,
            duration: 1,
          }
        );
        break;

      default:
        break;
    }
  }, []);

  const onButtonClick = contextSafe((cb = () => {}) => {
    gsap.fromTo(
      ".btn",
      { scale: 0.8 },
      {
        scale: 1,
        duration: 0.3,
        onComplete: () => {
          cb(true);
        },
      }
    );
  });

  return { onButtonClick };
};

export default useGSAPAnimation;
