import Chips from "react-chips";
import styles from "./index.module.scss";
import {
  prefix,
  ScreenType,
  useHomeContext,
} from "../../contexts/HomeContextProvider";
import { chipTheme, defaultTheme } from "../../Constants/ChipTheme";
import { loaderImg } from "../../Constants/AssetConstant";
import useGSAPAnimation from "../../hooks/useGSAPAnimation";
import { ANIMATION_TYPE } from "../../Constants";
import { Flex } from "antd";
import React, { useState } from "react";

export const doctorNameLimit = 22;
const inputs = [
  {
    id: "doctorsName",
    label: "Name",
    name: "doctorsName",
    className: "fade_in",
    type: "text",
    placeholder: "Name",
    required: true,
    disabled: false,
    readOnly: false,
    info: `* Enter your name (Max 18 characters). Name will appear in the video.`,
  },
  {
    id: "email",
    label: "Email",
    name: "email",
    className: "fade_in",
    type: "text",
    placeholder: "Email",
    required: true,
    disabled: false,
    readOnly: false,
    info: ``,
  },
];

const FormScreen = () => {
  //   useGSAPAnimation({
  //     target: ".fade_in",
  //     animationType: ANIMATION_TYPE.FADE_IN,
  //   });
  const {
    state,
    handleInputChange,
    resetFormErrors,
    saveDoctorDataInDB,
    updateScreen,
    updateMultipleState,
  } = useHomeContext();
  const { formDetails, formErrors } = state;

  const [consentCheck, setConsentChecked] = useState({
    terms: true,
    consent: true,
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setConsentChecked({ ...consentCheck, [name]: checked });
  };
  const openTermsUse = () => {
    window.open("https://docflix.com/terms-of-use", "_blank");
  };
  const handleCursorPosition = (e) => {
    const prefixLength = "Dr. ".length;

    requestAnimationFrame(() => {
      if (e.target.selectionStart < prefixLength) {
        e.target.selectionStart = e.target.selectionEnd = prefixLength;
      }
    });
  };
  return (
    <>
      <div className={`container ${styles.container}`}>
        <div className={`fade_in ${styles.heading}`}>
          <h1>Details</h1>
          <h4>Fill in your details to receive your video through E-Mail</h4>
        </div>
        {/* <div className={`fade_in ${styles.userImage}`}>
          <img src={state.base64Img} alt="" />
        </div> */}
        <div className={styles.formContainer}>
          <div className={styles.inputWrapper}>
            {inputs.map((input, index) => (
              <React.Fragment key={index}>
                <div
                  key={input.name}
                  className={`material-textfield ${styles["input-group"]}`}
                >
                  {input.type === "select" ? (
                    <select
                      defaultValue={input.placeholder}
                      id={input.name}
                      name={input.name}
                      className={`${input.className} ${styles.dp}`}
                      type={input.type}
                      placeholder={" "}
                      required={input.required}
                      value={
                        formDetails[input.name]
                          ? formDetails[input.name]
                          : input.placeholder
                      }
                      onFocus={resetFormErrors}
                      onChange={handleInputChange}
                    >
                      <option value="none">{input.placeholder}</option>

                      {Object.keys(state.appData?.[input.id])?.map((val) => (
                        <option value={val} key={val}>
                          {state.appData?.[input.id]?.[val]}
                        </option>
                      ))}
                    </select>
                  ) : input.type === "chip" ? (
                    <Chips
                      id={input.name}
                      value={formDetails[input.name].map((tag) => tag)}
                      onChange={(tags) =>
                        handleInputChange({ name: input.name, value: tags })
                      }
                      // suggestions={
                      //   input.name === "tags" ? allTags.map((tag) => tag.tag) : []
                      // }
                      // alwaysRenderSuggestions
                      // highlightFirstSuggestion
                      uniqueChips
                      // fromSuggestionsOnly={input.name === "speciality"}
                      required={input.required}
                      placeholder={input.placeholder}
                      chipTheme={chipTheme}
                      theme={defaultTheme}
                    />
                  ) : (
                    <input
                      autoComplete={"off"}
                      id={input.name}
                      name={input.name}
                      className={input.className}
                      type={input.type}
                      placeholder={input.placeholder}
                      value={formDetails[input.name]}
                      required={input.required}
                      disabled={input.disabled}
                      readOnly={input.readOnly}
                      onFocus={(e) => {
                        resetFormErrors(e);
                        handleCursorPosition(e);
                      }}
                      onChange={handleInputChange}
                      onClick={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onKeyDown={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onMouseUp={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onTouchStart={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onTouchEnd={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onSelect={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      onBeforeInput={
                        input.id === "doctorsName" ? handleCursorPosition : null
                      }
                      maxLength={
                        input.id === "doctorsName" ? doctorNameLimit : ""
                      }
                    />
                  )}
                  <label
                    htmlFor={input.name}
                    // className={`${input.info ? "infoLabel" : ""}`}
                  >
                    {input.label}
                  </label>
                  {formErrors[input.name] && (
                    <div className="error">{formErrors[input.name]}</div>
                  )}
                </div>
                {input.name === "doctorsName" && (
                  <ul className="infoLabelContainer">
                    <li className="infoLabel">
                      Enter your name (Max {doctorNameLimit - prefix.length}{" "}
                      characters).
                    </li>
                    <li className="infoLabel">
                      Name will appear on your personalized generated video.
                    </li>
                  </ul>
                )}
                {/* {input.info && (
                  <p className="infoLabel">
                    {input.info}
                  </p>
                )} */}
              </React.Fragment>
            ))}
          </div>
          <div className={styles.BottomWrapper}>
            <div className={styles.termsText}>
              <input
                type="checkbox"
                checked={consentCheck.terms}
                name="terms"
                onChange={handleChange}
                className={styles.checkbox}
              />
              <p>
                By proceeding you agree to the{" "}
                <a href="/terms-of-use" target="_blank">
                  Terms of use
                </a>{" "}
                {/* <a href="#" onClick={openTermsUse}>
                  Terms of use
                </a>{" "} */}
                and{" "}
                <a
                  href="https://www.mankindpharma.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className={styles.termsText}>
              <input
                type="checkbox"
                checked={consentCheck.consent}
                name="consent"
                onChange={handleChange}
                // style={{width: '2.5rem'}}
                className={`${styles.checkbox} ${styles.secondTextBox}`}
              />
              <p>
                {" "}
                By using this, you consent to the Al's use of your photo and
                agree to the company sharing Al-Generated content on social
                media, in accordance with privacy laws.
              </p>
            </div>
          </div>
        </div>
        <Flex gap="2rem">
          {/* <button
            style={{ background: "transparent" }}
            className="btn"
            onClick={() => {
              updateMultipleState({ base64Img: "", screen: ScreenType.START_SCREEN });
            }}
          // onClick={() => updateScreen(ScreenType.CAMERA_SCREEN)}
          >
            Back
          </button> */}
          <button
            disabled={
              state.isLoading || !consentCheck.consent || !consentCheck.terms
            }
            className="btn"
            onClick={saveDoctorDataInDB}
          >
            {state.isLoading ? (
              <img src={loaderImg} height="100%" alt="Loading" />
            ) : (
              "Next"
            )}
          </button>
        </Flex>
      </div>
      <img
        className={`highlightStrokes`}
        src={require("../../assets/images/Details.png")}
        alt=""
      />
    </>
  );
};

export default FormScreen;
