const defaultTheme = {
  chipsContainer: {
    display: "flex",
    position: "relative",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    alignItems: "center",
    flexWrap: "wrap",
    // padding: "0 1rem",
    borderRadius: 8,
    margin: "1rem 0",
    // ":focus": {
    //   border: "1px solid #aaa",
    // },
  },
  container: {
    flex: 1,
  },
  containerOpen: {},
  input: {
    border: "none",
    outline: "none",
    background: "none",
    boxSizing: "border-box",
    width: "100%",
    color: "#fff",
    padding: "1.2rem 1.5rem",
    marginTop: 0
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: "absolute",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    borderRadius: 8,
    zIndex: 10,
    left: 0,
    top: "100%",
    width: "100%",
    backgroundColor: "#2c4067",
    listStyle: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    overflow: "hidden",
  },
  suggestion: {
    padding: "5px 15px",
  },
  suggestionHighlighted: {
    background: "#275ea1",
  },
  sectionContainer: {},
  sectionTitle: {},
};

const chipTheme = {
  chip: {
    padding: "0.3rem 0.4rem",
    background: "#275ea1",
    borderRadius: 3,
    cursor: "default",
  },
  chipSelected: {
    background: "#888",
  },
  chipRemove: {
    fontWeight: "bold",
    cursor: "pointer",
    ":hover": {
      color: "#2c4067",
    },
  },
};

export { defaultTheme, chipTheme };
